import React from "react";
import path from "../../util/path";
import css from "./RelatedProducts.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RelatedProducts = (props) => {
  const { t, i18n } = useTranslation();
  const { related } = props;

  return (
    <div className={css.realtedProds}>
      {related?.length > 0 && (
        <>
          <h3>{t("single.related articles")}</h3>
          {related.map((rel, indx) => (
            <div className={css.relProd} key={indx}>
              <Link to={`/product/${rel.url}`} className={css.left}>
                <img src={path + rel.image} alt="" />
              </Link>
              <div className={css.right}>
                <h4>
                  <Link to={`/product/${rel.url}`}>{rel.name}</Link>
                </h4>
                <span className={css.code}>{rel.code}</span>
                <ul className={css.relTagWrap}>
                  {i18n.language === "it"
                    ? rel.tags.map((tag) => (
                        <li key={tag.id}>#{tag.name}</li>
                      ))
                    : rel.tagTranslations.map((tag) => (
                        <li key={tag.id}>#{tag.name}</li>
                      ))}
                </ul>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default RelatedProducts;
