import React, { useEffect, useState } from "react";
import css from "./Search.module.scss";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "../../components/SidebarBox/SidebarBox";
import Button from "../../components/UI/Button/Button";
import filterIcon from "../../assets/icons/filters.svg";
import * as actions from "../../store/actions";
import path from "../../util/path";
import addIcon from "../../assets/icons/wishlist-add.svg";
import removeIcon from "../../assets/icons/wishlist-remove.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/UI/Spinner/Spinner";

const Search = (props) => {
  const { t, i18n } = useTranslation();
  const moods = useSelector((store) => store.table.moods);
  const compositions = useSelector((store) => store.table.compositions);
  const collections = useSelector((store) => store.table.collections);
  const products = useSelector((store) => store.search.products);
  const loading = useSelector((store) => store.search.loading);
  const pagination = useSelector((store) => store.search.pagination);
  const wishlist = useSelector((store) => store.wishlist);

  const compositionValue = useSelector(
    (store) => store.filters.compositionValue
  );
  const moodValue = useSelector((store) => store.filters.moodValue);
  const applicationValue = useSelector(
    (store) => store.filters.applicationValue
  );
  const collectionValue = useSelector((store) => store.filters.collectionValue);

  const { searchValue } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [oldSV, setOldSV] = useState();
  const [page, setPage] = useState(null);
  const [cb, setCb] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (oldSV !== searchValue) {
      setPage(null);
      setCb(true);
    }
    setOldSV(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (page) {
      submitSearchHandler();
    }
  }, [page]);

  const resetFilter = () => {
    setPage(null);
    dispatch(actions.clearFilters());
  };

  useEffect(() => {
    if (props.location.query?.collectionId) {
      dispatch(actions.setCollectionValue(props.location.query.collectionId));
      dispatch(actions.setMoodValue([]));
      dispatch(actions.setCompositionValue([]));
      setCb(true);
    } else if (props.location.query?.applicationId) {
      if (props.location.query?.moodId) {
        dispatch(actions.setMoodValue([props.location.query.moodId]));
      } else {
        dispatch(actions.setMoodValue([]));
      }
      dispatch(actions.setApplicationValue(props.location.query.applicationId));
      dispatch(actions.setCompositionValue([]));
      setCb(true);
    } else {
      submitSearchHandler();
    }
  }, [props.location.query]);

  useEffect(() => {
    if (cb) {
      onSubmitByFilter();
    }
    return () => {
      setCb(false);
    };
  }, [cb]);

  const onSubmitByFilter = () => {
    setIsSidebarOpen(false);
    submitSearchHandler();
  };

  const submitSearchHandler = () => {
    let searchParam = searchValue || "";
    dispatch(
      actions.getSearchProducts(searchParam, page || 1, {
        applicationValue,
        collectionValue,
        moodValue,
        compositionValue,
      })
    );
  };

  const onRadioChange = (e, type) => {
    let val;
    if (e.target.value === "") {
      val = null;
    } else {
      val = e.target.value;
    }
    if (type === "application") {
      dispatch(actions.setApplicationValue(val));
    } else if (type === "collection") {
      dispatch(actions.setCollectionValue(val));
    }
    setPage(null);
  };

  const onCheckboxChange = (e, type) => {
    if (type === "mood") {
      if (moodValue.find((m) => m === e)) {
        dispatch(actions.setMoodValue(moodValue.filter((m) => m !== e)));
      } else {
        dispatch(actions.setMoodValue([...moodValue, e]));
      }
    } else if (type === "composition") {
      if (compositionValue.find((m) => m === e)) {
        dispatch(
          actions.setCompositionValue(compositionValue.filter((m) => m !== e))
        );
      } else {
        dispatch(actions.setCompositionValue([...compositionValue, e]));
      }
    }
    setPage(null);
  };

  const addToWishlistHandler = (inWish, prodId) => {
    if (wishlist.id) {
      if (inWish) {
        dispatch(actions.removeFromWishlist(wishlist.id, prodId));
      } else {
        dispatch(actions.addToWishlist(wishlist.id, prodId));
      }
    } else {
      history.push({ pathname: "/login", from: location.pathname });
    }
  };

  return (
    <div className="container">
      <div className={css.searchWrap}>
        <div
          className={css.filterIcon}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={filterIcon} alt="" />
        </div>
        <div
          className={`${css.sidebar} ${isSidebarOpen ? css.open : css.close}`}
        >
          {collections && (
            <Box title={t("search.collection")}>
              <label className={css.checkWrap}>
                {t("home.see all")}
                <input
                  type="radio"
                  value=""
                  checked={collectionValue === null}
                  name="collection"
                  onChange={(e) => onRadioChange(e, "collection")}
                />
                <span className={`${css.checkMark} ${css.radioMark}`}></span>
              </label>
              {collections.map((el) => (
                <label className={css.checkWrap} key={el.id}>
                  {el.name}
                  <input
                    type="radio"
                    value={el.id}
                    checked={el.id === parseInt(collectionValue)}
                    name="collection"
                    onChange={(e) => onRadioChange(e, "collection")}
                  />
                  <span className={`${css.checkMark} ${css.radioMark}`}></span>
                </label>
              ))}
            </Box>
          )}
          {moods && (
            <Box title={t("search.mood")}>
              {moods.map((el) => (
                <label className={css.checkWrap} key={el.id}>
                  {el.name}
                  <input
                    type="checkbox"
                    value={el.id}
                    checked={moodValue.find((m) => m === el.id) ? true : false}
                    name="mood"
                    onChange={(e) =>
                      onCheckboxChange(parseInt(e.target.value), "mood")
                    }
                  />
                  <span className={css.checkMark}></span>
                </label>
              ))}
            </Box>
          )}
          {compositions && (
            <Box title={t("search.composition")}>
              {compositions.map((el) => (
                <label className={css.checkWrap} key={el.id}>
                  {i18n.language === "it"
                    ? el.name
                    : i18n.language === "en"
                    ? el.nameEn
                    : i18n.language === "fr"
                    ? el.nameFr
                    : null}
                  <input
                    type="checkbox"
                    value={el.id}
                    checked={
                      compositionValue.find((m) => m === el.id) ? true : false
                    }
                    name="composition"
                    onChange={(e) =>
                      onCheckboxChange(parseInt(e.target.value), "composition")
                    }
                  />
                  <span className={css.checkMark}></span>
                </label>
              ))}
            </Box>
          )}
          {(applicationValue ||
            collectionValue ||
            moodValue.length > 0 ||
            compositionValue.length > 0) && (
            <span className={css.resetFilter} onClick={() => resetFilter()}>
              {t("search.reset filter")}
            </span>
          )}

          <Button
            text={t("search.search")}
            fullwidth
            onClick={() => onSubmitByFilter()}
          />
        </div>

        <div className={css.results}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {searchValue && (
                <h1>
                  {t("search.result for")} <strong>"{searchValue}"</strong>
                </h1>
              )}
              {products.length > 0 ? (
                <div className={css.prodsRow}>
                  {products.map((prod) => (
                    <div className={css.prodCol} key={prod.id}>
                      <div className={css.prodContent}>
                        <div
                          className={css.btnAdd}
                          onClick={() =>
                            addToWishlistHandler(
                              wishlist.products.find((pr) => pr.id === prod.id)
                                ? true
                                : false,
                              prod.id
                            )
                          }
                        >
                          {wishlist.products.find((pr) => pr.id === prod.id) ? (
                            <img src={removeIcon} alt="" />
                          ) : (
                            <img src={addIcon} alt="" />
                          )}
                        </div>
                        <span className={css.prodCode}>{prod.code}</span>
                        <div className={css.prodImage}>
                          <img src={path + prod.image} alt="" />
                        </div>
                        <div className={css.prodInfo}>
                          <h3>
                            <Link to={`/product/${prod.url}`}>{prod.name}</Link>
                          </h3>
                          <strong>{prod.collection?.name}</strong>
                          {prod.tags && (
                            <div className={css.prodTags}>
                              {i18n.language === "it"
                                ? prod.tags.map((tag) => (
                                    <span key={tag.id}>#{tag.name}</span>
                                  ))
                                : prod.tagTranslations
                                    .filter((l) => l.language === i18n.language)
                                    .map((tag) => (
                                      <span key={tag.id}>#{tag.name}</span>
                                    ))}
                            </div>
                          )}
                          <Link
                            className={css.prodMore}
                            to={`/product/${prod.url}`}
                          >
                            {t("search.more")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  {pagination.lastPage > 1 && (
                    <div className={css.pagination}>
                      {pagination.currentPage !== 1 && (
                        <>
                          <span
                            className={css.page}
                            onClick={() => setPage(pagination.currentPage - 1)}
                          >
                            «
                          </span>
                          <span className={css.page} onClick={() => setPage(1)}>
                            1
                          </span>
                        </>
                      )}
                      <span className={`${css.page} ${css.current}`}>
                        {pagination.currentPage}
                      </span>
                      {pagination.currentPage !== pagination.lastPage && (
                        <>
                          <span
                            className={css.page}
                            onClick={() => setPage(pagination.lastPage)}
                          >
                            {pagination.lastPage}
                          </span>
                          <span
                            className={css.page}
                            onClick={() => setPage(pagination.currentPage + 1)}
                          >
                            »
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <h3 className={css.noRes}>{t("search.no product")}</h3>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
