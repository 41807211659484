import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import Profile from "./views/Profile/Profile";
import Login from "./views/Login/Login";
import Registration from "./views/Registration/Registration";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Product from "./views/Product/Product";
import Wishlist from "./views/Wishlist/Wishlist";
import Search from "./views/Search/Search";
import ErrorModal from "./components/ErrorModal/ErrorModal";
import * as actions from "./store/actions";
import LostPassword from "./views/LostPassword/LostPassword";

const App = () => {
  const token = useSelector((store) => store.auth.token);
  const userId = useSelector((store) => store.auth.userId);
  const wishlistId = useSelector((store) => store.auth.wishlistId);
  const error = useSelector((store) => store.error.message);

  const dispatch = useDispatch();

  useEffect(() => {
    const sToken = localStorage.getItem("token");
    const sWishlistId = localStorage.getItem("wishlistId");
    const sExpirationDate = localStorage.getItem("expirationDate");
    const sUserId = localStorage.getItem("userId");
    const sExpiresIn = localStorage.getItem("expiresIn");

    if (sExpirationDate) {
      if (new Date(sExpirationDate) > new Date()) {
        let nextExpire = (new Date(sExpirationDate) - new Date()) / 1000;

        if (sToken && sUserId) {
          dispatch(
            actions.loginSuccessStartSession({
              token: sToken,
              user_id: sUserId,
              wishlistId: sWishlistId,
              expiresIn: nextExpire,
            })
          );
        }
      } else {
        const sRefreshToken = localStorage.getItem("refreshToken");
        dispatch(actions.refreshToken(sRefreshToken, sUserId, sWishlistId));
      }
    }
  }, []);

  useEffect(() => {
    if (token && userId) {
      dispatch(actions.getUserInfo(userId));
    }
  }, [userId]);

  useEffect(() => {
    dispatch(actions.getHomeProducts());
    dispatch(actions.getTables());
  });

  useEffect(() => {
    if (token && wishlistId) {
      dispatch(actions.getWishlist(wishlistId));
    }
  }, [wishlistId]);

  useEffect(() => {
    dispatch(actions.getFeaturedProducts());
    dispatch(actions.getHomeProducts());
    dispatch(actions.getTables());
  }, []);

  const clearEMHandler = () => {
    dispatch(actions.clearGeneralError());
  };

  return (
    <>
      <Header />
      <div className="wrapper">
        <Switch>
          <PrivateRoute path="/" exact component={Home} />
          <Route path="/login" exact component={Login} />
          <Route path="/registration" exact component={Registration} />
          <Route path="/lost-password" exact component={LostPassword} />
          <PrivateRoute path="/product/:prodUrl" exact component={Product} />
          <PrivateRoute path="/my-list" exact component={Wishlist} />
          <PrivateRoute path="/profile" exact component={Profile} />
          <PrivateRoute path="/search/:searchValue?" exact component={Search} />
        </Switch>
      </div>
      <ErrorModal
        open={error}
        message={error}
        onClose={() => clearEMHandler()}
      />
      <Footer />
    </>
  );
};

export default App;
