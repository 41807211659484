export const GENERAL_ERROR = "GENERAL_ERROR";
export const CLEAR_GENERAL_ERROR = "CLEAR_GENERAL_ERROR";

export const NEW_USER_REQUEST_SUCCESS = "NEW_USER_REQUEST_SUCCESS";
export const NEW_USER_REQUEST_ERROR = "NEW_USER_REQUEST_ERROR";
export const NEW_USER_REQUEST_LOAD = "NEW_USER_REQUEST_LOAD";
export const CLEAR_USER_REQUEST = "CLEAR_USER_REQUEST";

export const LOGIN_LOAD = "LOGIN_LOAD";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const EDIT_USER_LOAD = "EDIT_USER_LOAD";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const CLEAR_USER_COMPLETED = "CLEAR_USER_COMPLETED";
export const CHANGE_PASSWORD_LOAD = "CHANGE_PASSWORD_LOAD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CLEAR_CHANGE_PASSWORD_COMPLETED =
  "CLEAR_CHANGE_PASSWORD_COMPLETED";
export const LOST_PASSWORD_LOAD = "LOST_PASSWORD_LOAD";
export const LOST_PASSWORD_SUCCESS = "LOST_PASSWORD_SUCCESS";
export const LOST_PASSWORD_ERROR = "LOST_PASSWORD_ERROR";
export const CLEAR_LOST_PASSWORD_COMPLETED = "CLEAR_LOST_PASSWORD_COMPLETED";

export const GET_FEATURED_SUCCESS = "GET_FEATURED_SUCCESS";
export const GET_SINGLE_PRODUCT_LOAD = "GET_SINGLE_PRODUCT_LOAD";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS";
export const DOWNLOAD_CATALOG_LOAD = "DOWNLOAD_CATALOG_LOAD";
export const DOWNLOAD_CATALOG_SUCCESS = "DOWNLOAD_CATALOG_SUCCESS";

export const NEW_ORDER_SUCCESS = "NEW_ORDER_SUCCESS";
export const CLEAR_ORDER_STATUS = "CLEAR_ORDER_STATUS";

export const GET_TABLE_SUCCESS = "GET_TABLE_SUCCESS";

export const GET_SEARCH_PRODUCTS = "GET_SEARCH_PRODUCTS";
export const GET_SEARCH_LOAD = "GET_SEARCH_LOAD";
export const GET_HOME_PRODUCTS_SUCCESS = "GET_HOME_PRODUCTS_SUCCESS";

export const CLEAR_SEARCH_FILTERS = "CLEAR_SEARCH_FILTERS";
export const SET_COLLECTION_VALUE = "SET_COLLECTION_VALUE";
export const SET_APPLICATION_VALUE = "SET_APPLICATION_VALUE";
export const SET_MOOD_VALUE = "SET_MOOD_VALUE";
export const SET_COMPOSITION_VALUE = "SET_COMPOSITION_VALUE";
