import * as actionTypes from "./actionTypes";
import { generalError } from "./index";
import path from "../../util/path";
import axios from "axios";

export const addToWishlist = (wishId, prodId) => {
  return (dispatch) => {
    axios
      .post("/wishlist/add", { wishId: wishId, prodId: prodId })
      .then((res) => {
        dispatch(addToWishlistSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const downloadCatalog = (wishProducts) => {
  return (dispatch) => {
    dispatch(downloadCatalogLoad());
    axios
      .post(
        "/wishlist/downloadCatalog",
        { wishProducts },
        { responseType: "blob" }
      )
      .then((res) => {
        const pdf = window.URL.createObjectURL(res.data);
        window.open(pdf, "_blank");
        dispatch(downloadCatalogSuccess());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const downloadCatalogSuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_CATALOG_SUCCESS,
  };
};

export const downloadCatalogLoad = () => {
  return {
    type: actionTypes.DOWNLOAD_CATALOG_LOAD,
  };
};

export const addToWishlistSuccess = (newWishlist) => {
  return {
    type: actionTypes.ADD_TO_WISHLIST_SUCCESS,
    wishlist: newWishlist,
  };
};

export const removeFromWishlist = (wishId, prodId) => {
  return (dispatch) => {
    axios
      .delete("/wishlist/remove", { data: { wishId: wishId, prodId: prodId } })
      .then((res) => {
        dispatch(removeFromWishlistSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const removeFromWishlistSuccess = (newWishlist) => {
  return {
    type: actionTypes.REMOVE_FROM_WISHLIST_SUCCESS,
    wishlist: newWishlist,
  };
};

export const getWishlist = (wishlistId) => {
  return (dispatch) => {
    axios
      .get(`/wishlist/${wishlistId}`)
      .then((res) => {
        dispatch(getWishlistSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const getWishlistSuccess = (data) => {
  return {
    type: actionTypes.GET_WISHLIST_SUCCESS,
    wishlist: data,
  };
};

