import React, { useEffect, useState } from "react";
import css from "./Product.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import path from "../../util/path";
import addIcon from "../../assets/icons/wishlist-add.svg";
import removeIcon from "../../assets/icons/wishlist-remove.svg";
import shorLogo from "../../assets/images/short-logo.svg";
import Button from "../../components/UI/Button/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RelatedProducts from "../../components/RelatedProducts/RelatedProducts";
import Spinner from "../../components/UI/Spinner/Spinner";
import Lightbox from "../../components/UI/Lightbox/Lightbox";

const Product = (props) => {
  const { t, i18n } = useTranslation();
  const prodUrl = props.match.params.prodUrl;
  const wishlist = useSelector((store) => store.wishlist);
  const product = useSelector((store) => store.product.singleProduct);
  const related = useSelector((store) => store.product.relatedProds);
  const loading = useSelector((store) => store.product.singleLoading);

  const [inWishlist, setInWishlist] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);

  useEffect(() => {
    const isInWishlist = wishlist.products.find(
      (prod) => prod.id == product?.id
    );
    if (isInWishlist) {
      setInWishlist(true);
    } else {
      setInWishlist(false);
    }
  }, [wishlist.products, product]);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.getSingleProduct(prodUrl));
  }, [prodUrl]);

  const addToWishlistHandler = () => {
    if (wishlist.id) {
      if (inWishlist) {
        dispatch(actions.removeFromWishlist(wishlist.id, product.id));
      } else {
        dispatch(actions.addToWishlist(wishlist.id, product.id));
      }
    } else {
      history.push({ pathname: "/login", from: location.pathname });
    }
  };

  return (
    <div className="container">
      {product && !loading ? (
        <div className={css.product}>
          <div className={css.leftCol}>
            <div className={css.prodImage}>
              <img
                onClick={() => setOpenLightbox(true)}
                src={path + product.image}
                alt=""
              />
              <div
                className={css.addToList}
                onClick={() => addToWishlistHandler()}
              >
                {inWishlist ? (
                  <>
                    <img src={removeIcon} alt="" />
                    <strong>
                      {t("wishlist.remove")}
                      <br />
                      {t("wishlist.from list")}
                    </strong>
                  </>
                ) : (
                  <>
                    <img src={addIcon} alt="" />
                    <strong>
                      {t("wishlist.add")}
                      <br />
                      {t("wishlist.to list")}
                    </strong>
                  </>
                )}
              </div>
            </div>
            {product.photos?.length > 0 && (
              <div className={css.prodGallery}>
                {product.photos.map((photo) => (
                  <div className={css.photoCol} key={photo.id}>
                    <img src={path + photo.image} alt="" />
                    <span>{photo.caption}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={css.rightCol}>
            <div className={css.prodTitle}>
              <img src={shorLogo} alt="" />
              <div className={css.titleContent}>
                <h5>{product.collection?.name}</h5>
                <h1>{product.name}</h1>
                <span>{product.code}</span>
              </div>
            </div>
            <p className={css.prodDescription}>
              {i18n.language === "it"
                ? product.description
                : product.productTranslations.find(
                    (l) => l.language === i18n.language
                  )?.description}
            </p>
            <div className={css.prodInfo}>
              <h4>{t("single.technical info")}</h4>
              <ul className={css.infoList}>
                {product.composition_description && (
                  <li>
                    <span className={css.first}>{t("single.composition")}</span>
                    <span className={css.comp}>
                      {product.composition_description}
                    </span>
                  </li>
                )}
                {product.weight && (
                  <li>
                    <span className={css.first}>{t("single.weight")}</span>
                    <span>{product.weight} gr/mtl</span>
                  </li>
                )}
                {product.height && (
                  <li>
                    <span className={css.first}>{t("single.height")}</span>
                    <span>{product.height} cm</span>
                  </li>
                )}
                {product.sustainable && (
                  <li>
                    <span className={css.first}>Prodotto sostenibile</span>
                  </li>
                )}
                {product.note && (
                  <li>
                    <span className={css.first}>{t("single.note")}</span>
                    <span>{product.note}</span>
                  </li>
                )}
                {product.viscosity && (
                  <li>
                    <span className={css.first}>{t("single.viscosity")}</span>
                    <span>{product.viscosity}%</span>
                  </li>
                )}
                {product.softness && (
                  <li>
                    <span className={css.first}>{t("single.softness")}</span>
                    <span>{product.softness}%</span>
                  </li>
                )}
                {product.elasticity && (
                  <li>
                    <span className={css.first}>{t("single.elasticity")}</span>
                    <span>{product.elasticity}%</span>
                  </li>
                )}
              </ul>
            </div>
            {product.tags.length > 0 && (
              <ul className={css.tagWrap}>
                {i18n.language === "it"
                  ? product.tags.map((tag) => (
                      <span key={tag.id}>#{tag.name}</span>
                    ))
                  : product.tagTranslations
                      .filter((l) => l.language === i18n.language)
                      .map((tag) => <span key={tag.id}>#{tag.name}</span>)}
              </ul>
            )}
            <div className={css.addButton}>
              <Button
                fullwidth
                text={
                  inWishlist
                    ? t("wishlist.remove from list")
                    : t("wishlist.add to list")
                }
                onClick={() => addToWishlistHandler()}
              />
              <p>
                {t("single.product asterisk")}{" "}
                <Link to="/my-list">{t("wishlist.see your list")}</Link>
              </p>
            </div>
            <RelatedProducts related={related} />
          </div>
        </div>
      ) : (
        <div className={css.spinnerWrap}>
          <Spinner />
        </div>
      )}
      <Lightbox
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        link={product?.image}
      />
    </div>
  );
};

export default Product;
