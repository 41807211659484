import * as actionTypes from "../actions/actionTypes";

const initialState = {
  compositionValue: [],
  collectionValue: null,
  moodValue: [],
  applicationValue: null,
};

const filtersReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_SEARCH_FILTERS:
      return {
        ...initialState,
      };
    case actionTypes.SET_MOOD_VALUE:
      return {
        ...state,
        moodValue: action.value,
      };

    case actionTypes.SET_APPLICATION_VALUE:
      return {
        ...state,
        applicationValue: action.value,
      };

    case actionTypes.SET_COMPOSITION_VALUE:
      return {
        ...state,
        compositionValue: action.value,
      };

    case actionTypes.SET_COLLECTION_VALUE:
      return {
        ...state,
        collectionValue: action.value,
      };

    default:
      return state;
  }
};

export default filtersReducers;
