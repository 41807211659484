import React from "react";
import css from "./Button.module.scss";
import Spinner from "../Spinner/Spinner";

const Button = (props) => {
  const { fullwidth, secondary, load, text, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`${css.button} ${fullwidth && css.fullwidth} ${
        secondary ? css.secondary : css.primary
      }`}
      disabled={load && true}
    >
      {load ? <Spinner white /> : text}
    </button>
  );
};

export default Button;
