import React, { useState, useRef, useEffect } from "react";
import css from "./Profile.module.scss";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import saveIcon from "../../assets/icons/save.svg";
import editIcon from "../../assets/icons/edit.svg";
import Modal from "../../components/UI/Modal/Modal";
import check from "../../assets/icons/confirm.svg";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

  const user = useSelector((store) => store.user.user);
  const loading = useSelector((store) => store.user.editLoad);
  const completed = useSelector((store) => store.user.editCompleted);
  const changePasswordError = useSelector(
    (store) => store.user.changePasswordError
  );
  const changePasswordLoad = useSelector(
    (store) => store.user.changePasswordLoad
  );
  const changePasswordCompleted = useSelector(
    (store) => store.user.changePasswordCompleted
  );

  useEffect(() => {
    if (!changePasswordCompleted) {
      setIsChangePasswordOpen(false);
    }
  }, [changePasswordCompleted, setIsChangePasswordOpen]);

  const dispatch = useDispatch();

  const { handleSubmit, register, errors } = useForm();
  const {
    register: registerPsw,
    errors: errorsPsw,
    handleSubmit: handleSubmitPsw,
    watch,
  } = useForm();

  const newPassword = useRef({});
  newPassword.current = watch("new_password", "");

  const onSubmit = (values) => {
    dispatch(actions.editUser(user.id, values));
    setIsEditable(false);
  };

  const changePasswordSubmit = (values) => {
    dispatch(actions.changePassword(values));
  };

  return (
    <div className="container">
      <div className={css.profile}>
        <div className={css.profileWrap}>
          <span className={css.logoutWrite}>
            {t("profile.logout")}{" "}
            <span
              className={css.logoutButton}
              onClick={() => dispatch(actions.logout())}
            >
              Logout
            </span>
          </span>
          <h1>{t("general.account")}</h1>

          {user ? (
            <>
              <h3>{t("login.general informations")}</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                {loading ? (
                  <div className={css.abSpinner}>
                    <Spinner />
                  </div>
                ) : isEditable ? (
                  <button className={css.saveBtn}>
                    <img src={saveIcon} alt="" />
                  </button>
                ) : (
                  <img
                    className={css.editBtn}
                    src={editIcon}
                    alt=""
                    onClick={() => setIsEditable(true)}
                  />
                )}

                <div className={css.row}>
                  <div className={css.col}>
                    <label htmlFor="name">{t("forms.name")}</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      ref={register({
                        required: t("forms.name required"),
                      })}
                      defaultValue={user.name}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>{errors?.name?.message}</span>
                  </div>
                  <div className={css.col}>
                    <label htmlFor="name">{t("forms.surname")}</label>
                    <input
                      type="text"
                      id="surname"
                      name="surname"
                      ref={register({
                        required: t("forms.surname required"),
                      })}
                      defaultValue={user.surname}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>
                      {errors?.surname?.message}
                    </span>
                  </div>
                  <div className={css.col}>
                    <label htmlFor="company">{t("forms.company")}</label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      ref={register({
                        required: t("forms.company required"),
                      })}
                      defaultValue={user.company}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>
                      {errors?.company?.message}
                    </span>
                  </div>
                </div>
                <h3>{t("login.contact informations")}</h3>
                <div className={css.row}>
                  <div className={css.col}>
                    <label htmlFor="name">{t("forms.phone")}</label>
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      ref={register({
                        required: t("forms.phone required"),
                      })}
                      defaultValue={user.phone}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>{errors?.phone?.message}</span>
                  </div>
                  <div className={css.col}>
                    <label htmlFor="email">{t("forms.email")}</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      ref={register({
                        required: t("forms.email required"),
                      })}
                      defaultValue={user.email}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>{errors?.email?.message}</span>
                  </div>
                  <div className={css.col}>
                    <label htmlFor="address">{t("forms.address")}</label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      ref={register({
                        required: t("forms.address required"),
                      })}
                      defaultValue={user.email}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>
                      {errors?.address?.message}
                    </span>
                  </div>
                  <div className={css.colSmall}>
                    <label htmlFor="street_number">
                      {t("forms.street number")}
                    </label>
                    <input
                      type="text"
                      id="street_number"
                      name="street_number"
                      ref={register({
                        required: t("forms.street number required"),
                      })}
                      defaultValue={user.street_number}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>
                      {errors?.street_number?.message}
                    </span>
                  </div>
                  <div className={css.colSmall}>
                    <label htmlFor="city">{t("forms.city")}</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      ref={register({
                        required: t("forms.city required"),
                      })}
                      defaultValue={user.city}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>{errors?.city?.message}</span>
                  </div>
                  <div className={css.colSmall}>
                    <label htmlFor="province">{t("forms.province")}</label>
                    <input
                      type="text"
                      id="province"
                      name="province"
                      ref={register({
                        required: t("forms.province required"),
                      })}
                      defaultValue={user.province}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>
                      {errors?.province?.message}
                    </span>
                  </div>
                  <div className={css.colSmall}>
                    <label htmlFor="cap">{t("forms.cap")}</label>
                    <input
                      type="number"
                      id="cap"
                      name="cap"
                      ref={register({
                        required: t("forms.cap required"),
                      })}
                      defaultValue={user.cap}
                      disabled={!isEditable}
                    />
                    <span className={css.error}>{errors?.cap?.message}</span>
                  </div>
                </div>
              </form>
              <div className={css.changePswButton}>
                <Button
                  text={t("profile.change password")}
                  onClick={() => setIsChangePasswordOpen(true)}
                />
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>
        <Modal open={isChangePasswordOpen}>
          <div className={css.modalContent}>
            {changePasswordCompleted ? (
              <>
                <h3>{t("profile.change password successfully")}</h3>
                <Button
                  text={t("profile.ok")}
                  onClick={() =>
                    dispatch(actions.clearChangePasswordCompleted())
                  }
                />
              </>
            ) : (
              <>
                <h3>{t("profile.change password")}</h3>
                <form onSubmit={handleSubmitPsw(changePasswordSubmit)}>
                  <div className={css.row}>
                    <div className={css.col}>
                      <label htmlFor="old_password">
                        {t("profile.old password")}
                      </label>
                      <input
                        type="password"
                        id="old_password"
                        name="old_password"
                        ref={registerPsw({
                          required: t("profile.required"),
                        })}
                        defaultValue=""
                      />
                      <span className={css.error}>
                        {errorsPsw?.old_password?.message}
                      </span>
                    </div>
                    <div className={css.col}>
                      <label htmlFor="new_password">
                        {t("profile.new password")}
                      </label>
                      <input
                        type="password"
                        id="new_password"
                        name="new_password"
                        ref={registerPsw({
                          required: t("profile.required"),
                        })}
                        defaultValue=""
                      />
                      <span className={css.error}>
                        {errorsPsw?.new_password?.message}
                      </span>
                    </div>
                    <div className={css.col}>
                      <label htmlFor="password">
                        {t("profile.confirm new password")}
                      </label>
                      <input
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        ref={registerPsw({
                          validate: (value) =>
                            value === newPassword.current ||
                            t("profile.password no match"),
                          required: t("profile.required"),
                        })}
                        defaultValue=""
                      />
                      <span className={css.error}>
                        {errorsPsw?.confirm_password?.message}
                      </span>
                    </div>
                  </div>

                  {changePasswordError && (
                    <span className={css.errorMsg}>{changePasswordError}</span>
                  )}

                  <span
                    className={css.cancelChangePassword}
                    onClick={() => setIsChangePasswordOpen(false)}
                  >
                    {t("profile.cancel")}
                  </span>
                  <Button
                    text={t("profile.change password")}
                    load={changePasswordLoad}
                  />
                </form>
              </>
            )}
          </div>
        </Modal>
        <Modal open={completed}>
          <div className={css.modalContent}>
            <img src={check} alt="" />
            <h3>{t("login.edit confirm")}</h3>
            <Button
              secondary
              text={t("general.ok")}
              onClick={() => dispatch(actions.clearUserCompleted())}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
