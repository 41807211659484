import * as actionTypes from "./actionTypes";
import axios from "axios";

export const newUserRequest = (values) => {
  return (dispatch) => {
    dispatch(newUserRequestLoad());
    axios
      .post("/user/new-request", {
        ...values,
      })
      .then((res) => {
        dispatch(newUserRequestSuccess());
      })
      .catch((err) => {
        dispatch(newUserRequestError(err.response.data.errors));
      });
  };
};

export const newUserRequestLoad = () => {
  return {
    type: actionTypes.NEW_USER_REQUEST_LOAD,
  };
};

export const newUserRequestSuccess = () => {
  return {
    type: actionTypes.NEW_USER_REQUEST_SUCCESS,
  };
};

export const newUserRequestError = (err) => {
  return {
    type: actionTypes.NEW_USER_REQUEST_ERROR,
    errors: err,
  };
};

export const clearUserRequest = () => {
  return {
    type: actionTypes.CLEAR_USER_REQUEST,
  };
};
