import React from "react";
import css from "./Footer.module.scss";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import i18next from "i18next";

const Footer = () => {
  return (
    <footer className={css.footer}>
      <div className="container">
        <img className={css.logo} src={logo} alt="" />
        <div className={css.row}>
          <div className={css.col}>
            <ul>
              <li className={css.link}>
                <Link to="/">Torna al sito</Link>
              </li>
              <li className={css.link}>
                <Link to="/">Privacy policy</Link>
              </li>
              <li className={css.link}>
                <Link to="/">Impostazioni cookie</Link>
              </li>
            </ul>
          </div>
          <div className={css.col}>
            <ul>
              <li className={css.link}>
                <span>Tel: +39 031 490109</span>
              </li>
              <li className={css.link}>
                <span>Email: staff@attilioimperiali.it</span>
              </li>
            </ul>
          </div>
          <div className={css.col}>
            <span onClick={() => i18next.changeLanguage("en")}>English</span>
            <span onClick={() => i18next.changeLanguage("it")}>Italiano</span>
            <span onClick={() => i18next.changeLanguage("fr")}>Francese</span>
          </div>
        </div>
        <div className={css.row}>
          <div className={css.sub}>
            <div className={css.col}>
              <span className={css.grey}>
                COPYRIGHT ©2020 ATTILIO IMPERIALI S.P.A. VIA BULGARO, 13, 22075
                LURATE CACCIVIO (CO)
                <br />
                <br />
                P:IVA: 00295440135 - Camera di commercio di Como - REA 118972 -
                Cap. Soc.: € 180.000 i.v.
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
