import * as actionTypes from "./actionTypes";
import { generalError } from "./index";
import axios from "axios";

export const getSearchProducts = (searchValue, page, query) => {
  return (dispatch) => {
    dispatch(getSearchLoad());
    axios
      .get("/search/products", {
        params: {
          searchValue: searchValue,
          page,
          application: query.applicationValue,
          mood: query.moodValue,
          collection: query.collectionValue,
          composition: query.compositionValue,
        },
      })
      .then((res) => {
        dispatch(getSearchProductSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const getSearchProductSuccess = (data) => {
  return {
    type: actionTypes.GET_SEARCH_PRODUCTS,
    products: data.products,
    pagination: data.pagination,
  };
};

export const getSearchLoad = () => {
  return {
    type: actionTypes.GET_SEARCH_LOAD,
  };
};
