import React, { useState } from "react";
import css from "./Wishlist.module.scss";
import { useSelector, useDispatch } from "react-redux";
import closeIcon from "./../../assets/icons/close-white.svg";
import * as actions from "../../store/actions";
import path from "../../util/path";
import { Link } from "react-router-dom";
import Button from "../../components/UI/Button/Button";
import OrderModal from "../../components/OrderModal/OrderModal";
import { useTranslation } from "react-i18next";

const Wishlist = () => {
  const { t, i18n } = useTranslation();
  const wishId = useSelector((store) => store.wishlist.id);
  const wishProducts = useSelector((store) => store.wishlist.products);
  const downloadLoading = useSelector((store) => store.wishlist.catalogLoading);
  const user = useSelector((store) => store.user.user);

  const [wantOrder, setWantOrder] = useState(false);

  const dispatch = useDispatch();

  const removeFromWishlistHandler = (prodId) => {
    dispatch(actions.removeFromWishlist(wishId, prodId));
  };

  const newOrderHandler = (values) => {
    dispatch(actions.newOrder(user?.id, wishProducts, wishId, values));
  };

  return (
    <div className="container">
      <div className={css.wishlist}>
        <h1>{t("wishlist.your list")}</h1>

        {wishProducts.length > 0 ? (
          <>
            <div className={css.prodRow}>
              {wishProducts.map((prod) => (
                <div key={prod.id} className={css.prodCol}>
                  <div className={css.prodWrap}>
                    <img src={path + prod.image} alt="" />
                    <div className={css.prodInfo}>
                      <div
                        className={css.remove}
                        onClick={() => removeFromWishlistHandler(prod.id)}
                      >
                        <img src={closeIcon} alt="" />
                      </div>
                      <Link to={`/product/${prod.url}`}>
                        <h3>{prod.name}</h3>
                      </Link>
                      <span>{prod.code}</span>
                      <ul>
                        {i18n.language === "it"
                          ? prod.tags?.map((tag) => (
                              <li key={tag.id}>#{tag.name}</li>
                            ))
                          : prod.tagTranslations
                              ?.filter((l) => l.language === i18n.language)
                              .map((tag) => <li key={tag.id}>#{tag.name}</li>)}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={css.ctaCont}>
              <div className={css.ctaWrap}>
                <Button
                  text={t("wishlist.download catalog")}
                  load={downloadLoading}
                  onClick={() =>
                    dispatch(actions.downloadCatalog(wishProducts))
                  }
                />
              </div>
              <div>
                <Button
                  text={t("wishlist.request catalog")}
                  onClick={() => setWantOrder(true)}
                />
              </div>
            </div>
          </>
        ) : (
          <span className={css.noProd}>
            {t("wishlist.no products in list")}
          </span>
        )}
        <OrderModal
          isOpen={wantOrder}
          closed={() => setWantOrder(false)}
          newOrder={(values) => newOrderHandler(values)}
          user={user}
        />
      </div>
    </div>
  );
};

export default Wishlist;
