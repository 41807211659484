import React from "react";
import css from "./OrderModal.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "../UI/Modal/Modal";
import Button from "../UI/Button/Button";
import * as actions from "../../store/actions";
import { useHistory } from "react-router-dom";
import check from "../../assets/icons/confirm.svg";
import { useTranslation } from "react-i18next";

const OrderModal = (props) => {
  const { t } = useTranslation();
  const { isOpen, user, closed, newOrder } = props;

  const orderStatus = useSelector((store) => store.order.completed);

  const dispatch = useDispatch();
  const history = useHistory();

  const clearOrderStatusHandler = () => {
    dispatch(actions.clearOrderStatus());
    history.push("/");
  };

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (values) => {
    newOrder(values);
  };

  return (
    <Modal open={isOpen}>
      {orderStatus ? (
        <div className={css.orderCompleted}>
          <img src={check} alt="" />
          <h3>{t("wishlist.request confirm")}</h3>
          <p>
            {t("wishlist.see email")}
            <br />
            {t("wishlist.we will contact you")}
          </p>
          <Button
            fullwidth
            secondary
            text={t("general.ok")}
            onClick={() => clearOrderStatusHandler()}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className={css.orderForm}>
          <h3>{t("wishlist.confirm address for spedition")}</h3>
          <div className={css.row}>
            <div className={`${css.col} ${css.col100}`}>
              <label htmlFor="address">{t("forms.address")}</label>
              <input
                type="text"
                id="address"
                name="address"
                ref={register({
                  required: t("forms.address required"),
                })}
                defaultValue={user?.address}
              />
              <span className={css.error}>{errors?.address?.message}</span>
            </div>
          </div>
          <div className={css.row}>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="street_number">{t("forms.street number")}</label>
              <input
                type="text"
                id="street_number"
                name="street_number"
                ref={register({
                  required: t("forms.street number required"),
                })}
                defaultValue={user?.street_number}
              />
              <span className={css.error}>
                {errors?.street_number?.message}
              </span>
            </div>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="city">{t("forms.city")}</label>
              <input
                type="text"
                id="city"
                name="city"
                ref={register({
                  required: t("forms.city required"),
                })}
                defaultValue={user?.city}
              />
              <span className={css.error}>{errors?.city?.message}</span>
            </div>
          </div>
          <div className={css.row}>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="province">{t("forms.province")}</label>
              <input
                type="text"
                id="province"
                name="province"
                ref={register({
                  required: t("forms.province required"),
                })}
                defaultValue={user?.province}
              />
              <span className={css.error}>{errors?.province?.message}</span>
            </div>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="cap">{t("forms.cap")}</label>
              <input
                type="number"
                id="cap"
                name="cap"
                ref={register({
                  required: t("forms.cap required"),
                })}
                defaultValue={user?.cap}
              />
              <span className={css.error}>{errors?.cap?.message}</span>
            </div>
          </div>

          <div className={css.row}>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="courier">{t("forms.courier")}</label>
              <input
                type="text"
                id="courier"
                name="courier"
                ref={register({
                  required: t("forms.courier required"),
                })}
                defaultValue={user?.courier}
              />
              <span className={css.error}>{errors?.courier?.message}</span>
            </div>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="courier_code">{t("forms.courier_code")}</label>
              <input
                type="text"
                id="courier_code"
                name="courier_code"
                ref={register({
                  required: t("forms.courier_code required"),
                })}
                defaultValue={user?.courier_code}
              />
              <span className={css.error}>{errors?.courier_code?.message}</span>
            </div>
          </div>

          <h3>{t("wishlist.client indication")}</h3>
          <div className={css.row}>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="name">{t("forms.name")}</label>
              <input
                type="text"
                id="name"
                name="name"
                ref={register({
                  required: t("forms.name required"),
                })}
                defaultValue={user?.name}
                disabled={true}
              />
              <span className={css.error}>{errors?.name?.message}</span>
            </div>
            <div className={`${css.col} ${css.col50}`}>
              <label htmlFor="name">{t("forms.surname")}</label>
              <input
                type="text"
                id="surname"
                name="surname"
                ref={register({
                  required: t("forms.surname required"),
                })}
                defaultValue={user?.surname}
                disabled={true}
              />
              <span className={css.error}>{errors?.surname?.message}</span>
            </div>
          </div>
          <div className={css.row}>
            <div className={`${css.col} ${css.col100}`}>
              <label htmlFor="email">{t("forms.email")}</label>
              <input
                type="email"
                id="email"
                name="email"
                ref={register({
                  required: t("forms.email required"),
                })}
                defaultValue={user?.email}
                disabled={true}
              />
              <span className={css.error}>{errors?.email?.message}</span>
            </div>
          </div>
          <div className={css.row}>
            <div className={`${css.col} ${css.col100}`}>
              <label htmlFor="name">{t("forms.phone")}</label>
              <input
                type="number"
                id="phone"
                name="phone"
                ref={register({
                  required: t("forms.phone required"),
                })}
                defaultValue={user?.phone}
                disabled={true}
              />
              <span className={css.error}>{errors?.phone?.message}</span>
            </div>
          </div>
          <div className={css.ctaWrap}>
            <span onClick={closed}>{t("wishlist.cancel order")}</span>
            <Button text={t("wishlist.confirm order")} fullwidth />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default OrderModal;
